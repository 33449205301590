<template>
  <v-snackbar
    v-if="toastOptions"
    v-model="showToast"
    v-bind="toastOptions"
    class="global-snackbar"
    data-test="global-snackbar"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="toastOptions.message" />

    <template v-slot:actions>
      <qtm-icon-btn v-if="toastOptions.closable" color="white" icon="mdi-close" @click="showToast = false" />
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import useToastStore from '@/stores/toast'

const showToast = ref(false)

const toastStore = useToastStore()
const toastOptions = computed(() => toastStore.toastOptions)

watch(toastOptions, () => {
  if (!showToast.value) {
    showToast.value = true
    return
  }

  showToast.value = false
  setTimeout(() => {
    showToast.value = true
  }, 100)
})
</script>

<style lang="scss" scoped>
.global-snackbar {
  z-index: 10000 !important;
}
</style>
